import React from "react";
import Layout from "../../components/Layout";
import HeroImage from "../../img/images/cards/Group 2084.png";
import CTAButton from "../../components/CTAButton";
import StructureIcon from "../../img/images/icons/become-a-coach/noun_Flow Chart_19378.png";
import HelpIcon from "../../img/images/icons/become-a-coach/noun_teamwork_219695.png";
import CommunityIcon from "../../img/images/icons/become-a-coach/noun_Community_2977056.png";
import ListIcon from "../../img/images/icons/become-a-coach/noun_List_1015747.png";
import CTACard from "../../components/CTACard";
import LifelongAccessImage from "../../img/images/cards/Unschool Lifelong Access Card.png";
import LifelongAccessMobileImage from "../../img/images/Group 2254.png";
import ClockIcon from "../../img/images/icons/clock.svg";
import LaptopIcon from "../../img/images/icons/laptop.svg";
import PeopleIcon from "../../img/images/icons/people.svg";
import PillHeader from "../../components/PillHeader";
import Step1Image from "../../img/images/cards/coaching/steps/Group 2174.png";
import Step2Image from "../../img/images/cards/coaching/steps/Group 2173.png";
import Step3Image from "../../img/images/cards/coaching/steps/Group 2172.png";
import TestimonialSection from "../../components/TestimonialSection";
import IndustryCoaches from "../../components/IndustryCoaches";
import FAQSection from "../../components/FAQSection";
import NewsletterCard from "../../components/NewsletterCard";
import MobileHeroImage from "../../img/images/cards/Group 2112.png";
import PhoneIcon from "../../img/images/Group 2100.png";
import { graphql, StaticQuery } from "gatsby";
import { useLocation } from "@reach/router";
import "../../components/partner.scss";
import { faqs } from "../../utils/constants";

export default class PartnerWithUsPage extends React.Component {
  componentWillMount() {
    this.statsData = [
      {
        heading: "5000+",
        subheading: "Unschoolers",
        description: "Impact 5000+ Unschoolers",
        iconClass: "stats-icon-clock",
        textClass: "mobile-yellow-text",
        statClass: "",
        image: ClockIcon,
      },
      {
        heading: "50+",
        subheading: "Coaches",
        description: "A growing community of 50+ coaches",
        iconClass: "stats-icon-laptop",
        textClass: "mobile-blue-text",
        statClass: "stats-card-blue",
        image: LaptopIcon,
      },
      {
        heading: "3000+",
        subheading: "learners per month",
        description: "An ecosystem with 3000+ active learners per month",
        iconClass: "stats-icon-people",
        textClass: "mobile-orange-text",
        statClass: "stats-card-orange",
        image: PeopleIcon,
      },
    ];
  }

  render() {
    // const { data } = this.props;
    // const { testimonials, industry_coaches, Faqs } = data;
    let pageTitle =
      "Supercharge Your Company With Young Talent -Partner with Unschool";
    let pageDescription =
      "Be a part of the 500+ companies who trust Unschoolers to bring young, innovative and enthusiastic minds to their workplace.";
    return (
      <Layout title={pageTitle} description={pageDescription}>
        <section id={"become-a-coach-page"} className={"partner-with-us-page"}>
          <div id={"become-a-coach-hero"}>
            <div className={"row"}>
              <div className="col-md-6" id={"become-a-coach-hero-left"}>
                <h1>
                  Supercharge your company with{" "}
                  <span className={"become-a-coach-hero-gradient-text"}>
                    young talent.
                  </span>
                </h1>
                <CTAButton
                  text={"Register Now"}
                  link={"https://forms.gle/86g76hki1obiR7wz6"}
                />
                <div id={"become-a-coach-image-mobile-container"}>
                  <img
                    id={"become-a-coach-image-mobile"}
                    src={MobileHeroImage}
                    className={"desktop-hidden"}
                    alt="bc-img"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <img
                  id={"become-a-coach-image"}
                  className={"mobile-hidden"}
                  src={HeroImage}
                  alt={"Become a coach hero image"}
                />
              </div>
            </div>
          </div>

          <div id={"become-a-coach-all-you-need-to-know"}>
            <div className="row">
              <div className="col-md-4">
                <h2>Our unique selling points</h2>
                <CTAButton
                  text={"Register Now"}
                  className={"mobile-hidden"}
                  link={"https://forms.gle/86g76hki1obiR7wz6"}
                />
              </div>
              <div className="col-md-8" id={"become-a-coach-things-to-know"}>
                <div className="become-a-coach-thing-to-know-item">
                  <img src={ListIcon} alt="List Icon" />
                  <div>
                    <h4>Access to 500+ Colleges</h4>
                    <p>
                      We believe that every coach comes with their own unique
                      teaching style. UnSchool allows you to plan and run your
                      courses in ways you love.
                    </p>
                  </div>
                </div>
                <div className="become-a-coach-thing-to-know-item">
                  <img
                    src={StructureIcon}
                    alt="Structure Icon"
                    className={"b-a-c-icon-orange"}
                  />
                  <div>
                    <h4>Interns for your specific niche</h4>
                    <p>
                      We believe that every coach comes with their own unique
                      teaching style. UnSchool allows you to plan and run your
                      courses in ways you love.
                    </p>
                  </div>
                </div>
                <div className="become-a-coach-thing-to-know-item">
                  <img
                    src={CommunityIcon}
                    alt="Community Icon"
                    className={"b-a-c-icon-purple"}
                  />
                  <div>
                    <h4>Skilled and extensively coached talent</h4>
                    <p>
                      We believe that every coach comes with their own unique
                      teaching style. UnSchool allows you to plan and run your
                      courses in ways you love.
                    </p>
                  </div>
                </div>
                <div className="become-a-coach-thing-to-know-item">
                  <img
                    src={HelpIcon}
                    alt="Help Icon"
                    className={"b-a-c-icon-green"}
                  />
                  <div>
                    <h4>Long-term partnerships</h4>
                    <p>
                      We believe that every coach comes with their own unique
                      teaching style. UnSchool allows you to plan and run your
                      courses in ways you love.
                    </p>
                  </div>
                </div>

                <CTAButton
                  text={"Register Now"}
                  className={"desktop-hidden"}
                  link={"https://forms.gle/86g76hki1obiR7wz6"}
                />
              </div>
            </div>
          </div>

          <div id={"how-to-become-a-coach-section"}>
            <h4>
              Simple ste<span className={"yellow-underline-text"}>ps to</span>{" "}
              scale it up
            </h4>

            <div className="row" id={"become-a-coach-steps-container"}>
              <div className="col-md-4 become-a-coach-step">
                <div className={"become-a-coach-step-no mobile-hidden"}>1</div>
                <img src={Step1Image} alt="bc-img" />
                <div>
                  <div className={"become-a-coach-step-no desktop-hidden"}>
                    1
                  </div>
                  <h4>Register with us</h4>
                  <p>A quick ice-breaking form to get the ball rolling</p>
                </div>
              </div>
              <div className="col-md-4 become-a-coach-step">
                <div className={"become-a-coach-step-no mobile-hidden"}>2</div>
                <img src={Step2Image} alt="bc-img" />
                <div>
                  <div className={"become-a-coach-step-no desktop-hidden"}>
                    2
                  </div>
                  <h4>Post your opportunities</h4>
                  <p>
                    Be as descriptive as possible, so we can be better
                    matchmakers!
                  </p>
                </div>
              </div>
              <div className="col-md-4 become-a-coach-step">
                <div className={"become-a-coach-step-no mobile-hidden"}>3</div>
                <img src={Step3Image} alt="bc-img" />
                <div>
                  <div className={"become-a-coach-step-no desktop-hidden"}>
                    3
                  </div>
                  <h4>Receive applications weekly</h4>
                  <p>
                    Hire the best fit for your company from the filtered and
                    quality checked applications you receive
                  </p>
                </div>
              </div>
            </div>
          </div>

          <PillHeader text={"Partner packages"} />

          <div id={"partner-packages-container"}>
            <div
              className={"partner-package-item"}
              onClick={() =>
                window.open(
                  "https://form.jotform.com/202833255909459?chooseA=Basic Package"
                )
              }
            >
              <div className="partner-package-header">
                <h2>Basic</h2>
                <h3>3 months</h3>
              </div>
              <div className="partner-package-body">
                <h6>Plan includes:</h6>
                <ul>
                  <li>15 unique opportunity postings</li>
                  <li>25 applications per post</li>
                  <li>Branding on company newsletter</li>
                </ul>
              </div>
            </div>
            <div
              className={"partner-package-item"}
              onClick={() =>
                window.open(
                  "https://form.jotform.com/202833255909459?chooseA=Pro Package"
                )
              }
            >
              <div className="partner-package-header">
                <h2>Pro</h2>
                <h3>6 months</h3>
              </div>
              <div className="partner-package-body">
                <h6>Plan includes:</h6>
                <ul>
                  <li>40 unique opportunity postings</li>
                  <li>45 applications per post</li>
                  <li>
                    Branding on company newsletter and project certificates
                  </li>
                </ul>
              </div>
            </div>
            <div
              className={"partner-package-item"}
              onClick={() =>
                window.open(
                  "https://form.jotform.com/202833255909459?chooseA=Premium Package"
                )
              }
            >
              <div className="partner-package-header">
                <h2>Premium</h2>
                <h3>12 months</h3>
              </div>
              <div className="partner-package-body">
                <h6>Plan includes:</h6>
                <ul>
                  <li>70 unique opportunity postings</li>
                  <li>65 applications per post</li>
                  <li>
                    Branding on company newsletter and project certificates
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div id={"partner-get-in-touch"}>
            <div>
              Get in touch with us to learn about our package offerings in
              detail or get a quote.
            </div>
            <div className={"desktop-hidden partner-get-in-touch-mobile"}>
              <img src={PhoneIcon} alt="phone-icon" />
            </div>
            <div className={"mobile-hidden"}>
              <CTAButton
                text={"Contact Us"}
                link={"mailto:partner@unschool.in"}
                mailto
              />
            </div>
          </div>

          <CTACardHOC
            LifelongAccessImage={LifelongAccessImage}
            LifelongAccessMobileImage={LifelongAccessMobileImage}
          />

          <TestimonialSection />

          <IndustryCoaches />

          <FAQSection questions={faqs} />

          <NewsletterCard />
        </section>
      </Layout>
    );
  }
}

const CTACardHOC = ({ LifelongAccessImage, LifelongAccessMobileImage }) => {
  const location = useLocation();
  return (
    <CTACard
      id={"become-a-coach-cta-card"}
      heading={"Let’s get started?"}
      subheading={
        "Be a part of the 500+ companies who trust Unschoolers to bring young, innovative and enthusiastic minds to their workplace."
      }
      buttonText={"Work with us"}
      buttonLink={`${location.origin}/work-with-us/?tab=openings`}
      desktopImage={LifelongAccessImage}
      mobileImage={LifelongAccessMobileImage}
    />
  );
};
